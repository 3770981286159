import React from 'react';
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer class="footer">
          <div class="container">
              <div class="row">
                  <div class="col-md-4 col-sm-12">
                      <h2>About Us</h2>
                      <p>
                            Brainobrain is one of the world's leading Skill Development Centre for children, having its Corporate Office in Chenai, India.
                            Brainobrain Kids Academy Private Limited is an ISO9001:2000 Certified institution established in the year 2003, manned with
                            more than a decade of international experience in kids' education and training. Brainobrain's global presence has 975 centres
                            in 43 Countries.
                      </p>
                  </div>
                  <div class="col-md-4 col-sm-12">
                      <h2>Useful Links</h2>
                      <ul class="list-unstyled link-list">
                          <li><a href="/">Home <i class="fa fa-angle-right"></i></a></li>
                          <li><Link to="/about">About Us <i class="fa fa-angle-right"></i></Link></li>
                          <li><Link to="/offer">What We offer <i class="fa fa-angle-right"></i></Link></li>
                          <li><Link to="/franchising">Franchising <i class="fa fa-angle-right"></i></Link></li>
                      </ul>
                  </div>
                  <div class="col-md-4 col-sm-12 map-img">
                      <h2>Contact Us</h2>
                      <address class="md-margin-bottom-40">
                          <i style={{color: "#f7931d"}} class="fas fa-map-marker-alt"></i> 18217 Dan Judson St.Milton Park <br/>
                          <i style={{color: "#f7931d"}} class="fas fa-map-marker-alt"></i> Harare <br/>
                          <i style={{color: "#f7931d"}} class="fas fa-map-marker-alt"></i> Zimbabwe <br/>
                          <i style={{color: "#f7931d"}} class="fas fa-blender-phone"></i> Phone:+263 772974015 / +263 772306110<br/>
                          <i style={{color: "#f7931d"}} class="fas fa-envelope"></i> Email: <a href="mailto:info@possibiltiescognitiveskills.com" class="">info@possibiltiescognitiveskills.com</a><br/>
                          <i style={{color: "#f7931d"}} class="fas fa-globe"></i> Web: <a href="https://www.brainobrain.co.zw" class="">www.brainobrain.co.zw</a>
                      </address>
                  </div>
              </div>
              
              
              <div class="nav-box row clearfix">
                  <div class="inner col-md-9 clearfix">
                      <ul class="footer-nav clearfix">
                          <li><a href="/">Home</a></li>
                          <li><Link to="/about">About Us</Link></li>
                          <li><Link to="/offer">What We offer</Link></li>
                          <li><Link to="/franchising">Franchising</Link></li>
                      </ul>  
                  </div>
                    <div class="donate-link col-md-3"><a href="donate.html" class="btn btn-primary "><span class="btn-title">Apply Now</span></a></div>
              </div>
          </div>
      </footer>
      <div class="copy">
              <div class="container">
                  <a href="https://www.melgotech.co.zw/">2022 &copy; All Rights Reserved | Designed and Developed by Melgotech Incorporated</a>
                  <span>
                    <a href="http://melgotech.co.zw"><i class="fab fa-facebook-f"></i></a>
                    <a href="http://melgotech.co.zw"><i class="fab fa-instagram"></i></a>
                  </span>
              </div>
      </div>
    </>
  )
}

export default Footer;
