import React from 'react'

const Courses = () => {
  return (
    <>
    <section class="events">
        <div class="container">
           
            <div class="event-ro row">
                <div class="col-md-6 col-sm-6">
                    <div class="event-box">
                        <img src="assets/images/events/brainobrainbig.jpg" alt="" />
                        <hr />
                        <h4>Brainobrain Program</h4>
                        
                        <p class="raises"><span>Age Group :</span> 7 to 14 years </p>
                        <p class="raises"><span>Number of Levels :</span> 10 </p>
                        <p class="raises"><span>Duration of each Module :</span> 3 Months</p>
                        <p class="raises"><span>Classes :</span> Once In a week for 2 hours</p><br />
                    </div>
                </div>
                <div class="col-md-6 col-sm-6">
                    <div class="event-box">
                        <img src="assets/images/events/littlebob.png" alt="" />
                        <hr />
                        <h4>Little Brainobrain</h4>
                        
                        <p class="raises"><span>Age Group :</span> 4 to 6 years </p>
                        <p class="raises"><span>Number of Levels :</span> 4 </p>
                        <p class="raises"><span>Duration of each Module :</span> 3 Months</p>
                        <p class="raises"><span>Classes :</span> Once In a week for 2 hours</p><br/>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    </>
  )
}

export default Courses
