import React from "react";
import Fform from "./Fform";

const Franchise = () => {
    return (
        <>
            <div class="about-us">
                <div class="container">
                    <div class="row natur-row no-margin w-100">
                        
                        <div class="col-md-6">
                            <h2 align="center"> Franchisee Opportunity:</h2>
                            <p style={{textAlign:"start"}}>
                                Education is one field, which offers tremendous growth opportunities in this modern era.
                                Every parent is concerned about his/her child’s learning potential. World population is
                                growing tremendously and hence there is an unprecedented need for finding solutions for
                                all the world problems, which are increasingly growing every day. This necessitates a huge
                                need for leaders, not just leaders but every one who can think on his own to solve his own
                                problems and the world problems. This responsibility is partly taken by us, as we train 
                                each & every child in Brainobrain to learn quickly & effectively, to imagine, to concentrate,
                                to use our brain more effectively than ever. This is the business opportunity that we are aiming at.<br/><br/>
                                Are you a self-motivated person, who consistently aims for quality and customer satisfaction in any business?<br/><br/>
                                If you can fulfill our requirements, then you have everything to become a successful Brainobrain Franchisee in your area. 
                            </p>
                        </div>
                        <div class="image-part col-md-6 mv-img">
                            <img style={{boxShadow: "0px 4px 8px 0px gray", borderRadius:"10px"}}src="assets/images/brainobraint.jpg" alt="Why jpg" />
                        </div>
                    </div>
                </div>
            </div>
            <Fform />
        </>
    );
}

 export default Franchise;