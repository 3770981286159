import React from "react";

const Form = () => {
return (
    <>
        <div className="row contact-rooo no-margin">
            <div className="container">
                <div className="row">
                    <div style={{padding:"20px"}} className="col-sm-7">
                        <h2 >Contact Form</h2> <br/>
                        <div className="row cont-row">
                            <div  className="col-sm-3"><label>Enter Name </label><span>:</span></div>
                            <div className="col-sm-8"><input type="text" placeholder="Enter Name" name="name" className="form-control input-sm"  /></div>
                        </div>
                        <div  className="row cont-row">
                            <div  className="col-sm-3"><label>Email Address </label><span>:</span></div>
                            <div className="col-sm-8"><input type="text" name="name" placeholder="Enter Email Address" className="form-control input-sm" /></div>
                        </div>
                        <div  className="row cont-row">
                            <div  className="col-sm-3"><label>Mobile Number</label><span>:</span></div>
                            <div className="col-sm-8"><input type="text" name="name" placeholder="Enter Mobile Number" className="form-control input-sm"  /></div>
                        </div>
                        <div  className="row cont-row">
                            <div  className="col-sm-3"><label>Enter Message</label><span>:</span></div>
                            <div className="col-sm-8">
                                <textarea rows="5" placeholder="Enter Your Message" className="form-control input-sm"></textarea>
                            </div>
                        </div>
                        <div style={{marginTop:"10px"}} className="row">
                            <div style={{paddingTop:"10px"}} className="col-sm-3"><label></label></div>
                            <div className="col-sm-8">
                                <button className="btn btn-primary btn-sm">Send Message</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-5">
                        <div style={{margin:"50px"}} className="serv">
                            <h2 style={{marginTop:"10px"}}>Address</h2>
                            <i style={{color: "#f7931d"}} class="fas fa-map-marker-alt"></i> 18217 Dan Judson St.Milton Park, <br/>
                            <i style={{color: "#f7931d"}} class="fas fa-map-marker-alt"></i> Harare<br/>
                            <i style={{color: "#f7931d"}} class="fas fa-map-marker-alt"></i> Zimbabwe<br/>
                            <i style={{color: "#f7931d"}} class="fas fa-blender-phone"></i> Phone: +263 772974015 / +263 772306110<br/>
                            <i style={{color: "#f7931d"}} class="fas fa-envelope"></i> Email: info@possibiltiescognitiveskills.com<br/>
                            <i style={{color: "#f7931d"}} class="fas fa-globe"></i> Website: www.brainobrain.co.zw<br/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
);
}
 export default Form;