import React from 'react'

const Achievements = () => {
  return (
    <>
        <div className="doctor-message">
            <div className="inner-lay">
                <div className="container">
                    <div className="row session-title">
                        <h2>Brainobrain Global Statistics</h2>
                        <p>Simply Brainobrain in numbers. Brainobrain International’s global presence. Abacus mental artihmetic education around the globe.</p>
                    </div>
                    <div className="row">
                        <div className="col-sm-3 numb">
                            <h3>17</h3>
                            <span>YEARS OF EXPERIENCE</span>
                        </div>
                        <div className="col-sm-3 numb">
                            <h3>43</h3>
                            <span>COUNTRIES WORLDWIDE</span>
                        </div>
                        <div className="col-sm-3 numb">
                            <h3>975</h3>
                            <span>TRAINING CENTERS</span>
                        </div>
                        <div className="col-sm-3 numb">
                            <h3>4,00,000+</h3>
                            <span>CHILDREN BENEFITED WORLDWIDE </span>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </>
  )
}

export default Achievements
