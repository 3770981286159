import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({title, page}) => {
  return (
    <>
        <div class="page-nav no-margin row">
            <div class="container">
                <div class="row">
                    <h2>{title}</h2>
                    <ul>
                        <li> <Link to="/"><i class="fas fa-home"></i> Home</Link></li>
                        <li><i class="fas fa-angle-double-right"></i>{page}</li>
                    </ul>
                </div>
            </div>
        </div>
    </>
  )
}

export default Breadcrumb;
