import React from 'react'

const AboutSection = () => {
  return (
    <>
        <div className="about-us container-fluid">
            <div className="container">

                <div className="row natur-row no-margin w-100">
                    <div className="text-part col-md-6">
                        <h2>About The Brainobrain program</h2>
                        <p>
                            Brainobrain is one of the world's leading Skill Development Centre for children, having its Corporate Office in Chenai, India.
                            Brainobrain Kids Academy Private Limited is an ISO9001:2000 Certified institution established in the year 2003, manned with
                            more than a decade of international experience in kids' education and training. Brainobrain's global presence has 975 centres
                            in 43 Countries.<br/><br/>
                            We offer skill-development programme for children that comprises a unique combination of Brain Skills, Life Skills and NLP
                            ( Nuero Linguistic Programming) Skills, which is the first of its kind in the field of education. This unique skill development
                            programme capacitate theh children's brain to identify its potential and to develop someof the basic skills for learning such 
                            as Memory, Visualisation, Concentration, Listening Skills, Learning Ability, Creativity, Self Confidence, Speed & Accuracy, etc.
                            <br/><br/>
                            We do Believe that every child is unique and has got the potentials to be the leaders and role-models of future. It is our responsibility to help
                            our young minds acquire the right skills that would make the difference. The transformed lives of more than 1,00,000 children all around the globe
                            holds the testimony to our high-quality programmes.<br/><br/>
                            It is the game changer for children. It is the fusion of Abacus acumen (Brain Skills) along with the latest science of human excellence called Neuro Linguistic Programming
                            (NLP Skills) and Personality development (LIFT Skills).

                        </p>
                    </div>
                    <div className="image-part col-md-6">
                        <div className="about-quick-box row">
                            <h2>Some of the Course Contents</h2>
                            <div className="col-md-6">
                                <div className="about-qcard">
                                    <i className="fas fa-user-tag"></i>
                                    <p>Personality Analysis</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="about-qcard ">
                                    <i className="fas fa-brain red"></i>
                                    <p>International NLP</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="about-qcard ">
                                    <i className="fas fa-calculator yell"></i>
                                    <p>Abacus Mental Arithmetic</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="about-qcard ">
                                    <i className="fas fa-hands-helping blu"></i>
                                    <p>Group Discussions & Role Plays</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="about-qcard ">
                                    <i className="fas fa-school yell"></i>
                                    <p>Vocabulary & Spelling Skills</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="about-qcard ">
                                    <i className="fas fa-american-sign-language-interpreting blu"></i>
                                    <p align="center">Good Habits & Etiquette</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default AboutSection;
