import React from 'react'

const Mission = () => {
  return (
    <>
        <section className="container-fluid mission-vision">
            <div className="container">
                <div className="row mission">
                    <div className="col-md-6 mv-det">
                        <h1>Our Mission</h1>
                        <p>Our mission is to nurture the children of this world in bringing out the real leader in him/her, who are the hopes for the future of this world.</p>
                    </div>
                    <div className="col-md-6 mv-img">
                        <img src="assets/images/brainobrain.png" alt="" />
                    </div>
                </div>
                <div className="row vision">
                    <div className="col-md-6 mv-img">
                        <img src="assets/images/brainobrain2.png" alt="" />
                    </div>
                    <div className="col-md-6 mv-det">
                        <h1>Our Vision</h1>
                        <p>
                            Our vision is to become one of the admirable organisation in this world in spreading the values of love, honesty, fun and happiness through our children.
                           Our aim is to promote heart-based education for every one all over the world.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Mission
