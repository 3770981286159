import React from 'react'

const WhyBob = () => {
  return (
    <>
        <div class="about-us">
            <div class="container">
                <div class="row natur-row no-margin w-100">
                    
                    <div class="col-md-6">
                        <h2 align="center"> Why the Brainobrain Program:</h2>
                        <ul class="list-unstyled link-list skrr">
                            <li><i class="fa fa-angle-right"></i>  International Concept</li>
                            <li><i class="fa fa-angle-right"></i>  World’s Latest Kids-Friendly Syllabus</li>
                            <li><i class="fa fa-angle-right"></i>  Child Centric Course Modules</li>
                            <li><i class="fa fa-angle-right"></i>  Stringent Quality Policies</li>
                            <li><i class="fa fa-angle-right"></i>  Competent, Trained, Certified & Committed Instructors</li>
                            <li><i class="fa fa-angle-right"></i>  Quality Assessment through Regional & National Competitions</li>
                            <li><i class="fa fa-angle-right"></i>  Additional Training in Neuro Linguistic Programming (NLP).</li>
                         </ul>
                    </div>
                    <div class="image-part col-md-6 mv-img">
                         <img style={{boxShadow: "0px 4px 8px 0px gray", borderRadius:"10px"}}src="assets/images/why.jpg" alt="Why jpg" />
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default WhyBob;
