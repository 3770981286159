import React from 'react'

const Content = () => {
  return (
    <>
        <div class="about-us container-fluid">
            <div class="container">
                <h2 align="center"> Content from the Courses:</h2>
                <div class="row natur-row no-margin w-100">
                    
                    <div class="col-md-6">
                        <ul class="list-unstyled link-list skrr">
                            <li><i class="fa fa-angle-right"></i>  Abacus Mental Arithmetic</li>
                            <li><i class="fa fa-angle-right"></i>  International NLP</li>
                            <li><i class="fa fa-angle-right"></i>  Personality Analysis</li>
                            <li><i class="fa fa-angle-right"></i>  Group Discussions & Role Plays</li>
                            <li><i class="fa fa-angle-right"></i>  Good Habits & Etiquette</li>
                            <li><i class="fa fa-angle-right"></i>  Vocabulary & Spelling Skills</li>
                            <li><i class="fa fa-angle-right"></i>  Observation & Concentration Skills</li>
                         </ul>
                    </div>
                    <div class=" col-md-6">
                         <ul class="list-unstyled link-list skrr">
                            <li><i class="fa fa-angle-right"></i>  Quick & Retentive Memory</li>
                            <li><i class="fa fa-angle-right"></i>  Positive Belief System</li>
                            <li><i class="fa fa-angle-right"></i>  Leadership Skills</li>
                            <li><i class="fa fa-angle-right"></i>  Attitude Formation</li>
                            <li><i class="fa fa-angle-right"></i>  Mental Gymnastics</li>
                            <li><i class="fa fa-angle-right"></i>  Creative Art & Story Writing</li>
                            <li><i class="fa fa-angle-right"></i>  and much more...</li>
                         </ul>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Content
