import React from 'react'
import Breadcrumb from '../Components/Breadcrumb';
import Form from '../Components/Form';
import Sitemap from '../Components/Sitemap';

const Contact = () => {
  return (
    <>
      <Breadcrumb title="Contact Us" page="Contact-Us" />
      <Sitemap />
      <Form />
    </>
  )
}

export default Contact;
