import React from 'react'

const Sitemap = () => {
  return (
    <>
    <div style={{marginTop: "0px"}} className="row no-margin">
        <iframe title="sitemap" style={{width: "100%",border:0}} src="https://maps.google.com/maps?q=Milton%20Park,%20Harare,%20Zimbabwe&t=&z=13&ie=UTF8&iwloc=&output=embed"  height="450" frameborder="0" allowfullscreen></iframe>
    </div>  
    </>
  )
}

export default Sitemap
