import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <>
      <header className="continer-fluid ">
          <div  className="header-top">
              <div className="container">
                  <div className="row col-det">
                      <div className="col-lg-6 d-none d-lg-block">
                          <ul className="ulleft">
                              <li>
                                  <i style={{color: "#f7931d"}} className="far fa-envelope"></i>
                                  info@possibilitiescognitiveskills.com
                                  <span>|</span></li>
                              <li>
                                  <i style={{color: "#f7931d"}} className="fas fa-phone-volume"></i>
                                  +263 077 297 4015 | +263 077 230 6110</li>  
                          </ul>
                      </div>
                      <div className="col-lg-3 col-md-6 folouws">
                        
                          <ul className="ulright">
                            <li> <small>Follow Us </small>:</li>
                              <li>
                                  <i className="fab fa-facebook-square"></i>
                              </li>
                              <li>
                                  <i className="fab fa-instagram"></i>
                              </li>
                              <li><img style={{maxWidth:"100px"}} src="assets/images/flag.png" alt="" /></li>
                          </ul>
                      </div>
                      <div className="col-lg-3 d-none d-md-block col-md-6">
                          <img style={{maxHeight:"150px"}}src="assets/images/logo.png" alt="" />
                      </div>
                  </div>
              </div>
          </div>
          <div id="menu-jk" className="header-bottom">
              <div className="container">
                  <div className="row nav-row">
                      <div className="col-lg-3 col-md-12 logo">
                          <a href="/">
                              <img style={{maxWidth:"150px"}} src="assets/images/logo-white.png" alt="" />
                              <a data-toggle="collapse" data-target="#menu" href="#menu"><i className="fas d-block d-lg-none  small-menu fa-bars"></i></a>
                          </a>
                      </div>
                      <div id="menu" className="col-lg-9 col-md-12 d-none d-lg-block nav-col">
                                  <ul className="navbad">
                                      <li className="nav-item">
                                          <a href="/" className="nav-link" >Home</a>
                                      </li>
                                      <li className="nav-item">
                                          <Link to="/about" className="nav-link" >About</Link>
                                      </li>
                                      <li className="nav-item">
                                          <Link to="/offer" className="nav-link" >What We offer</Link>
                                      </li>
                                      <li className="nav-item">
                                          <Link to="/franchising" className="nav-link" >Franchising</Link>
                                      </li>
                                      <li className="nav-item">
                                          <Link to="/contact-us" className="nav-link" >Contact Us</Link>
                                      </li>
                                  </ul>
                      </div>
                  </div>
              </div>
          </div> 
      </header>
    </>
  )
}

export default Navbar
