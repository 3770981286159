import React from "react";

const Fform = () => {
return (
    <>
        <div className="row contact-rooo no-margin">
            <div className="container">
                <div className="row">
                    <div className="col-sm-5">
                        <div style={{margin:"50px"}} className="serv">
                            <img style={{boxShadow: "0px 4px 8px 0px gray", borderRadius:"10px"}}src="assets/images/enquire.jpg" alt="Why jpg" />
                        </div>
                    </div>
                    <div style={{padding:"20px"}} className="col-sm-7">
                        <h2>Franchise Enquiry Form</h2> <br/>
                        <div className="row cont-row">
                            <div  className="col-sm-3"><label>Enter Name </label><span>:</span></div>
                            <div className="col-sm-8"><input type="text" placeholder="Enter Name" name="name" className="form-control input-sm"  /></div>
                        </div>
                        <div  className="row cont-row">
                            <div  className="col-sm-3"><label>Email Address </label><span>:</span></div>
                            <div className="col-sm-8"><input type="text" name="name" placeholder="Enter Email Address" className="form-control input-sm" /></div>
                        </div>
                        <div  className="row cont-row">
                            <div  className="col-sm-3"><label>Mobile Number</label><span>:</span></div>
                            <div className="col-sm-8"><input type="text" name="name" placeholder="Enter Mobile Number" className="form-control input-sm"  /></div>
                        </div>
                        <div  className="row cont-row">
                            <div  className="col-sm-3"><label>Enquiry Type</label><span>:</span></div>
                            <div className="col-sm-8">
                                <select type="text" name="name" placeholder="Enter Mobile Number" className="form-control input-sm">
                                    <option value="Student Enquiry">Student Enquiry</option>
                                    <option value="Faculty Enquiry">Faculty Enquiry</option>
                                    <option value="Franchisee Enquiry">Franchisee Enquiry</option>
                                    <option value="NLP">NLP</option>
                                    <option value="General Enquiry">General Enquiry</option>
                                </select>
                            </div>
                        </div>
                        <div  className="row cont-row">
                            <div  className="col-sm-3"><label>Introduce Yourself</label><span>:</span></div>
                            <div className="col-sm-8">
                                <textarea rows="5" placeholder="Enter Your Message" className="form-control input-sm"></textarea>
                            </div>
                        </div>
                        <div style={{marginTop:"10px"}} className="row">
                            <div style={{paddingTop:"10px"}} className="col-sm-3"><label></label></div>
                            <div className="col-sm-8">
                                <button className="btn btn-primary btn-sm">Send Enquiry</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
);
}
 export default Fform;