import React from 'react'
import Breadcrumb from '../Components/Breadcrumb';
import Franchise from '../Components/Franchise';

const Franchising = () => {
  return (
    <>
      <Breadcrumb title="Franchising" page="franchising" />
      <Franchise />
    </>
  )
}

export default Franchising;
