import React from 'react'

const Slider = () => {
  return (
    <>
        <div class="slider">
            
            <div class="owl-carousel ">
                <div class="slider-img">
                    <div class="item">
                        <div class="slider-img"><img src="assets/images/slider/slider-3.jpg" alt="" /></div>
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-offset-2 col-lg-8 col-md-offset-2 col-md-8 col-sm-12 col-xs-12">
                                    <div class="animated bounceInDown slider-captions">
                                        <h1 class="slider-title">Empowering Young Geniuses Worldwide</h1>
                                        <p class="slider-text hidden-xs">Skill Development Program for 4-14 year old Kids</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="slider-img"><img src="assets/images/slider/brainobrain1.jpg" alt="" /></div>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-offset-2 col-lg-8 col-md-offset-2 col-md-8 col-sm-12 col-xs-12">
                                <div class="slider-captions ">
                                    <h1 class="slider-title">International Success Reports</h1>
                                    <p class="slider-text hidden-xs">Brainobrain is one of the world’s leading Children Institutes for Self Empowerment. Brainobrain operates in 40 countries and has more than 900 successful franchise centers.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Slider;
