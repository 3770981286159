import React from 'react'
import Breadcrumb from '../Components/Breadcrumb'
import Content from '../Components/Content'
import Courses from '../Components/Courses'
import WhyBob from '../Components/WhyBob'

const Offer = () => {
  return (
    <>
      <Breadcrumb title="What we offer" page="What we offer" />
      <Courses />
      <Content />
      <WhyBob />
    </>
  )
}

export default Offer
