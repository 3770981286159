import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

// import of site styling
import "./asset/css/all.min.css";
import "./asset/css/animate.css";
import "./asset/css/fontawsom-all.min.css";
import "./asset/plugins/slider/css/owl.carousel.min.css";
import "./asset/plugins/slider/css/owl.theme.default.css";
import "./asset/css/style.css";
//import 'bootstrap/dist/css/bootstrap.min.css';

// import footer and nav components of the website
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";

// import all the pages from the pages folder
import Home from "./Pages/Home";
import About from "./Pages/About";
import Offer from "./Pages/Offer";
import Franchising from "./Pages/Franchising";
import Contact from "./Pages/Contact";

function App() {
  return (
    <Router >
      <div>
      <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/offer' element={<Offer />} />
          <Route path='/franchising' element={<Franchising />} />
          <Route path='/contact-us' element={<Contact />} />

        </Routes>
      <Footer />
      </div>
    </Router>
  );
}

export default App;
