import React from 'react'
import AboutSection from '../Components/AboutSection'
import Breadcrumb from '../Components/Breadcrumb'
import Mission from '../Components/Mission'

const About = () => {
  return (
    <>
        <Breadcrumb title="About The Brainobrain Program" page="About Us" />
        <AboutSection />
        <Mission />
    </>
  )
}

export default About;
