import React from 'react';
import Slider from '../Components/Slider';
import AboutSection from '../Components/AboutSection';
import Mission from '../Components/Mission';
import Achievements from '../Components/Achievements';

const Home = () => {
  return (
    <>
      <Slider />
      <AboutSection />
      <Mission />
      <Achievements />
    </>
  )
};

export default Home;
